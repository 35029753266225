// Define theme colors
// Nanolab colors
$red: #9e0505;
$green: #0c7928;
$blue: #1e3d72;
$yellow: #d9a108;
$white: #ffffff;
$gray: #767676;
$black: #222;

// Overwrite bootstrap colors
$primary: $blue;
$secondary: #7c7c7e;
$success: lighten($green, 5%);
$danger: lighten($red, 10%);
$warning: lighten($yellow, 5%);
$info: lighten($blue, 10%);
$dark: $black;

$primary-alt: lighten($primary, 20%);
$secondary-alt: $secondary;
$success-alt: $success;
$danger-alt: $danger;
$warning-alt: $warning;
$info-alt: $info;
$dark-alt: $dark;

// Admin LTE sidebar colors
$sidebar-dark-bg: $primary;
$sidebar-dark-color: $white;
$sidebar-dark-hover-bg: $white;
$sidebar-dark-hover-color: $black;

$sidebar-dark-submenu-color: $white;
$sidebar-dark-submenu-hover-bg: $white;
$sidebar-dark-submenu-hover-color: $black;
$sidebar-dark-submenu-active-bg: $white;
$sidebar-dark-submenu-active-color: $black;

// Admin LTE main background color
$main-bg: lighten($dark, 7.5%);
$dark-main-bg: $main-bg;

// Link color should be white on a dark background
$link-color: $white;
