@use 'sass:math';

$grid-map: (
    xxs: 1,
    md: 2, // This breakpoint also enables the wide layout
    xl: 3,
    xxl: 4
);
$simple-grid-margin: map-get($spacers, 1);

.grid-layout {
  margin-bottom: $spacer;
  display: grid;
  gap: map-get($spacers, 2);
  grid-auto-rows: minmax(auto, auto);
  grid-auto-flow: dense;
  max-width: 100%;

  @each $breakpoint, $repeat in $grid-map {
    @include media-breakpoint-up($breakpoint) {
      grid-template-columns: repeat($repeat, 1fr);
      @for $i from 2 through $repeat {
        &.grid-count-#{$i} {
          grid-template-columns: repeat($i, 1fr);
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .wide {
      grid-column-end: span 2;
    }
  }

  .fw {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  .card {
    margin-bottom: 0;
  }
}

.simple-grid-layout {
  display: flex;
  flex-wrap: wrap;
  margin: 0 $simple-grid-margin * -1 $simple-grid-margin * -2;

  > div, > li {
    margin: 0 $simple-grid-margin $simple-grid-margin * 2;
    flex-basis: 1px;
    flex-grow: 1;
    flex-shrink: 0;

    min-width: calc(#{percentage(0.25)} - #{$simple-grid-margin * 2});
    max-width: calc(#{percentage(0.25)} - #{$simple-grid-margin * 2});

    @include media-breakpoint-down(xl) {
      min-width: calc(#{percentage(math.div(1, 3))} - #{$simple-grid-margin * 2});
      max-width: calc(#{percentage(math.div(1, 3))} - #{$simple-grid-margin * 2});
    }

    @include media-breakpoint-down(lg) {
      min-width: calc(#{percentage(0.5)} - #{$simple-grid-margin * 2});
      max-width: calc(#{percentage(0.5)} - #{$simple-grid-margin * 2});
    }

    @include media-breakpoint-down(sm) {
      min-width: calc(#{percentage(1)} - #{$simple-grid-margin * 2});
      max-width: calc(#{percentage(1)} - #{$simple-grid-margin * 2});
    }
  }
}
