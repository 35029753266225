@import 'colors';

$table-dark-bg: $dark;

$modal-backdrop-opacity: 0.8;
$modal-content-border-color: $gray;

$tooltip-opacity: 1;
$tooltip-bg: $white;
$tooltip-color: $black;

$input-bg: $dark;
$input-border-color: $gray;
$input-color: $white;

///////////////////////////////////////////////////////////////
/// Vendor imports
///////////////////////////////////////////////////////////////

// Shared layout, which includes bootstrap
@import '~@drenso/layout-shared';
@import 'forms';

@import '~bootstrap-vue/src/index';

.layout-navbar-fixed.layout-fixed .wrapper .sidebar {
  margin-top: 0;
}

.sidebar {
  height: 100vh !important;
}

body {
  // To prevent a white bar
  background-color: $main-bg;
}

.sf-toolbar-clearer{
  // To prevent a white bar
  background: $main-bg;
}

.tool-image {
  width: 100%;
  max-height: 15vh;
}

$fa-css-prefix: fa;
@import '~@fortawesome/fontawesome-free/scss/animated';

.init-loader {
  color: #ffffff;
  padding-top: 2rem;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .loader-icon {
    font-size: 6rem;
    width: 1em;
  }

  .loader-text {
    font-size: larger;
    margin-top: 0.5rem;
    text-transform: uppercase;
  }
}

@import 'grid_layout';
